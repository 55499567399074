import { Button, Form, Input, Modal, Typography, notification } from "antd"
import { useState } from "react"
import API from "../../../../../API"

const CrearGrupoModal=({abrir,cerrar,registros})=>{
    const [cargando,setCargando] = useState(false)

    const guardar = async (payload)=>{
        try{
            setCargando(true)
            await API.post('muestra',{...payload,registros})
            notification.success({
                description:'Muestra creada correctamente, puedes visualizarla en la sección muestras'
            })
            cerrar(false)
        }catch(err){
            notification.error({
                description:err?.response?.data?.mensaje || err.toString()
            })
            
        }finally{
            setCargando(false)
        }
    }

    return <Modal open={abrir} closable={!cargando} footer={[]} onCancel={()=>cerrar(false)} title="Crear muestra">
        <Typography.Paragraph>Pruebas seleccionadas {registros.length}</Typography.Paragraph>
        <Form disabled={cargando} layout="vertical" onFinish={guardar}>
        <Form.Item label="Nombre" name="nombre" rules={[{required:true,message:'Ingrese un nombre para la muestra'}]}>
            <Input/>
        </Form.Item>
        <Button loading={cargando} type="primary" htmlType="submit">Crear muestra</Button>
        </Form>
    </Modal>
}

export default CrearGrupoModal