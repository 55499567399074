import { Button } from "antd"
import CrearGrupoModal from "./Modal"
import { useState } from "react"

const CrearGrupo=({registros})=>{
    const [abrir,setAbrir] = useState(false)

    if(!registros?.length){
        return null
    }

    return <>
    <CrearGrupoModal abrir={abrir} cerrar={setAbrir} registros={registros}/>
    <Button type="primary" onClick={()=>setAbrir(true)}>Crear muestra</Button>
    </>
}

export default CrearGrupo